<template>
  <div class="app-rating">
    <div v-for="i in length" :key="i" class="star">
      <img v-if="i <= value" src="@/assets/star.svg" alt="star" :width="size" @click="change(i)">
      <img v-else src="@/assets/star-empty.svg" alt="star-empty" :width="size" @click="change(i)">
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppRatingInput',
  props: {
    rating: {
      type: Number,
      default: 0
    },
    size: {
      type: Number,
      default: 20
    },
    length: {
      type: Number,
      default: 5
    },
    isReadOnly: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      value: 0
    }
  },
  methods: {
    change (i) {
      if (!this.isReadOnly) {
        this.value = i
        this.$emit('change', i)
      }
    }
  },
  mounted () {
    this.value = this.rating
  }
}
</script>

<style scoped>
.app-rating {
  display: flex;
  flex-wrap: nowrap;
  text-align: center;
}
.app-rating > div {
  width: 100px;
  flex: 0 1 calc(20% - 8px);
  margin: 0 10px;
  text-align: center;
}
.star {
  cursor: pointer;
  /* margin: 0 1px; */
}
</style>