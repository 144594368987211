<template>
  <div class="rating-form mt-2">
    <h1 class="rf-title mb-2"> Order : {{data.invoice_number}}</h1>
    <v-card v-if="data" class="od-content">
      <v-layout wrap>
        <v-flex xs1>
          <img :src="data.img_product" alt="image" width="100%">
        </v-flex>
        <v-flex xs9 class="pl-4">
          <p class="mb-0 od-sub"> {{data.product_code}} </p>
          <p class="mb-4 od-sub2"> {{ data.product_name }}</p>
          <p class="mb-0 od-sub"> {{ data.ukuran }}</p>
        </v-flex>
      </v-layout>
    </v-card>

    <v-card class="od-content mt-4 mb-4" elevation="0">

      <p class="od-sub3 mt-4" align="center">Rate Our Product</p>
      <v-layout wrap>
        <v-flex xs8 offset-xs2>
          <AppRatingInput :size="50" @change="setDesciption($event)" :is-read-only="false"/>
        </v-flex>
      </v-layout>
      <p class="od-sub3 mt-4" align="center">{{ description }}</p>

      <!-- Image Section -->
      <p class="mb-0 od-sub5">Add Photos</p>
      <v-layout wrap>
        <v-flex xs8 offset-xs2>
          <v-layout wrap>
            <v-flex xs4>
              <div class="od-image-section mt-4">
                <span v-if="!review" class="od-image-label" @click="browse(1)">
                  <v-icon>mdi-image</v-icon>
                </span>

                <img v-if="review" class="od-image" :src="url" alt="preview">

                <span v-if="review" class="od-image-reset" @click="resetImage(1)">
                  <v-icon>mdi-close</v-icon>
                </span>

                <v-file-input
                  v-model="review"
                  ref="inputFile"
                  class="pl-4 pr-4 od-image-input"
                  :rules="imageRules"
                  accept="image/png, image/jpeg, image/bmp"
                  :hide-input="true"
                  style="visibility: hidden"
                ></v-file-input>
              </div>
            </v-flex>

            <v-flex xs4>
              <div class="od-image-section mt-4">
                <span v-if="!review2" class="od-image-label" @click="browse(2)">
                  <v-icon>mdi-image</v-icon>
                </span>

                <img v-if="review2" class="od-image" :src="url2" alt="preview">

                <span v-if="review2" class="od-image-reset" @click="resetImage(2)">
                  <v-icon>mdi-close</v-icon>
                </span>

                <v-file-input
                  v-model="review2"
                  ref="inputFile2"
                  class="pl-4 pr-4 od-image-input"
                  :rules="imageRules"
                  accept="image/png, image/jpeg, image/bmp"
                  :hide-input="true"
                  style="visibility: hidden"
                ></v-file-input>
              </div>
            </v-flex>

            <v-flex xs4>
              <div class="od-image-section mt-4">
                <span v-if="!review3" class="od-image-label" @click="browse(3)">
                  <v-icon>mdi-image</v-icon>
                </span>

                <img v-if="review3" class="od-image" :src="url3" alt="preview">

                <span v-if="review3" class="od-image-reset" @click="resetImage(3)">
                  <v-icon>mdi-close</v-icon>
                </span>

                <v-file-input
                  v-model="review3"
                  ref="inputFile3"
                  class="pl-4 pr-4 od-image-input"
                  :rules="imageRules"
                  accept="image/png, image/jpeg, image/bmp"
                  :hide-input="true"
                  style="visibility: hidden"
                ></v-file-input>
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <!-- End of Image Section -->
      
      <p class="od-sub3 mt-4 mb-2" align="left">Review</p>

      <div style="text-align:left">
        <TemplateText @add="addReview($event)"/>
      </div>

      <v-textarea
        v-model="text"
        class="text-review mt-4" 
        color="#fff"
        solo
        label="Write a review"
        :rules="rules"
        :hide-details="true"
      ></v-textarea>
      <p class="mt-3 od-sub4" align="right">
        <span v-if="!text"> 
          {{ remaining }} characters remaining
        </span>
        <span v-else-if="text && text.length <= this.maxValue">
          {{ remaining }} characters remaining
        </span>
        <span v-else class="error--text"> Max {{this.maxValue}} characters</span>
      </p>
      <!-- <v-checkbox
        v-model="hideName"
        class="od-checkbox"
        label="Hide your name`"
      ></v-checkbox> -->
    </v-card>


    <v-btn class="od-btn" tile width="20%" height="44" color="#000" @click="submit()">
      <span class="white--text"> SUBMIT </span>
    </v-btn>

  </div>
</template>

<script>
import axios from '@/axios'
import { mapState } from 'vuex'
import AppRatingInput from '@/components/Rating/Rating/AppRatingInput'
import TemplateText from '@/components/Rating/Rating/TemplateText'

export default {
  name: 'RatingFormDekstop',
  components: {AppRatingInput, TemplateText },
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
     ...mapState([
      'isMobile',
      'token'
    ])
  },
  watch: {
    text (val) {
      if (val && val.length <= this.maxValue) {
        this.remaining = this.maxValue - this.text.length
      } else {
        this.remaining = this.maxValue
      }
    },
    review (val) {
      if (val) {
        this.setUrl(1)
      } else {
        if (this.review2) {
          let temp = this.review2
          this.review = temp
          this.review2 = null
        } else if (this.review3) {
          let temp = this.review3
          this.review = temp
          this.review3 = null
        } else {
          this.url = null
        }
      }
    },
    review2 (val) {
      if (val) {
        if (this.review) {
          this.setUrl(2)
        } else {
          let temp = this.review2
          this.review = temp
          this.review2 = null
        }
      } else {
        if (this.review3) {
          let temp = this.review3
          this.review2 = temp
          this.review3 = null
        } else {
          this.url2 = null
        }
      }
    },
    review3 (val) {
      if (val) {
        if (this.review) {
          if (this.review2) {
            this.setUrl(3)
          } else {
            let temp = this.review3
            this.review2 = temp
            this.review3 = null
          } 
        } else {
          let temp = this.review3
          this.review = temp
          this.review3 = null
        }
      } else {
        this.url3 = null
      }
    }
  },
  data () {
    return {
      text: '',
      rating: 0,
      description: '',
      rules: [v => v.length <= this.maxValue || 'Max 500 characters'],
      maxValue: 500,
      remaining: 500,
      review: null,
      review2: null,
      review3: null,
      imageRules: [
        value => !value || value.size < 2000000 || 'Avatar harus lebih kecil dari 2 MB!',
      ],
      hideName: false,
      response: ''
    }
  },
  methods: {
    setDesciption (val) {
      this.rating = val
      if (val === 0) {
        this.description = ''
      } else if (val === 1) {
        this.description = 'Dissapointed'
      } else if (val === 2) {
        this.description = 'Poor'
      } else if (val === 3) {
        this.description = 'Okay'
      } else if (val === 4) {
        this.description = 'Good'
      } else if (val === 5) {
        this.description = 'Statisfied'
      }
    },
    browse (index) {
      if (index === 1) {
        this.$refs.inputFile.$refs.input.click()
      } else if (index === 2) {
        this.$refs.inputFile2.$refs.input.click()
      } else {
        this.$refs.inputFile3.$refs.input.click()
      }
    },
    resetImage (index) {
      if (index === 1) {
        this.review = null
      } else if (index === 2) {
        this.review2 = null
      } else {
        this.review3 = null
      }
    },
    setUrl (index) {
      if (index === 1) {
        this.url = URL.createObjectURL(this.review)
      } else if (index === 2) {
        this.url2 = URL.createObjectURL(this.review2)
      } else {
        this.url3 = URL.createObjectURL(this.review3)
      }
    },
    submit () {
      if (this.rating && (this.text && this.text.trim() !== '')) {
        this.send()
      } else {
        this.$store.commit('setSnackbar', true)
        this.$store.commit('setText', 'Oops, kamu belum memilih rating atau mengisi review')
      }
    },
    addReview (item) {
      this.text += ' ' + item
    },
    send () {
      this.$store.commit('setIsLoading', true)
      
      axios.defaults.headers = {
        id_brand: process.env.VUE_APP_BRAND,
        token: this.token
      }

      let bodyFormData = new FormData()
      bodyFormData.append('id_rating', this.data.id_rating)
      bodyFormData.append('rating', this.rating)
      bodyFormData.append('review', this.text)
      bodyFormData.append('img1', this.review)
      bodyFormData.append('img2', this.review2)
      bodyFormData.append('img3', this.review3)

      axios.post('/api/v1/pages/rating', bodyFormData)
        .then(response => {
          this.response = response
          this.$emit('success')
        })
        .catch(error => {
          this.errorHandling(error)
        })
        .finally(() => {
          this.$store.commit('setIsLoading', false)
        })
    }
  }
}
</script>

<style scoped>
.rating-form {
  background-color: #fff;
  position: relative;
  padding: 0px;
  text-align: center !important;
}
.rf-title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-align: left;
  color: #000000;
}
.od-content {
  padding: 6px 12px;
  text-align: center;
}
.od-image-section {
  width: 120px;
  height: 120px;
  background: #FFFFFF;
  border: 2px dashed #C4C4C4;
  border-radius: 5px;
  position: relative;
  display: inline-block;
  margin: 0 10px;
}
.od-image {
  width: 110px;
  height: 110px;
  object-fit: cover;
  object-position: center;

  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
}
.od-image-input {
  position: absolute;
  bottom: 0;
  left: 0;
}
.od-image-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: justify;
  color: #000000;
  cursor: pointer;
}
.od-image-reset {
  position: absolute;
  top: 3%;
  right: 3%;
  background: rgba(128,128,128);
}
.od-sub {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: justify;
  color: #000000;
}
.od-sub2 {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: justify;
  color: #000000;
}
.od-sub3 {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
.od-sub4 {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #666261;
}
.od-sub5 {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #000000
}
.od-checkbox >>> .v-label {
  font-size: 12px !important;
}
.text-review >>> .v-label {
  font-size: 14px !important;
}
</style>