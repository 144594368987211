<template>
 <v-layout wrap id="myreview">
    <v-flex xs12>
      <NotificationHeader />
    </v-flex>
    <v-flex xs12 v-if="!viewRatingFormDekstop">
      <v-tabs
        v-model="tab"
        background-color="transparent"
        color="#000"
        :grow="isMobile ? true : false"
      >
        <v-tab>
          <span>Waiting Review</span> 
          <span v-if="reviewNumber" class="ml-1"> ({{reviewNumber}})</span>
        </v-tab>
        <v-tab>
          <span>Reviewed</span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" background-color="transparent">
        <v-tab-item>
          <v-card
            flat
          >
            <Waiting v-if="tab === 0" :success="success" @rating="openRatingForm($event)" @ratingDekstop="showRatingForm($event)"/>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card
            flat
          >
            <Reviewed v-if="tab === 1" />
          </v-card>
        </v-tab-item>
      </v-tabs-items>

    </v-flex>

    <v-flex xs12 v-else>
      <RatingFormDekstop 
        v-if="viewRatingFormDekstop"
        :data="selectedData"
        @close="viewRatingForm = false"
        @success="handleSuccess()"/>
    </v-flex>

    <v-dialog v-model="showNotif"
      width="300px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <Notif :notif="message" @close="handleClose()" />
    </v-dialog>

    <v-dialog v-model="viewRatingForm"
      :fullscreen="true"
      transition="dialog-bottom-transition"
      scrollable
    >
      <RatingForm 
        v-if="viewRatingForm"
        :data="selectedData"
        @close="viewRatingForm = false"
        @success="handleSuccess()"/>
    </v-dialog>
 </v-layout>
</template>

<script>
import { mapState } from 'vuex'
import Notif from '@/components/Notif'
import Reviewed from '@/components/MyReview/Reviewed'
import Waiting from '@/components/MyReview/Waiting'
import RatingForm from '@/components/Rating/Rating/RatingForm'
import RatingFormDekstop from '@/components/Rating/Dekstop/Rating/RatingFormDekstop'
import NotificationHeader from '@/components/Notification/NotificationHeader'

export default {
  name: 'ReviewPage',
  components: { Notif, Waiting, Reviewed, RatingForm, RatingFormDekstop, NotificationHeader},
  computed: {
    ...mapState([
      'isMobile',
      'reviewNumber'
    ])
  },
  data () {
    return {
      showNotif: false,
      tab: 0,
      selectedData: null,
      viewRatingForm: false,
      viewRatingFormDekstop: false,
      message: null,
      success: false
    }
  },
  methods: {
    openRatingForm (data) {
      this.success = false
      this.selectedData = data
      this.viewRatingForm = true
    },
    showRatingForm (data) {
      this.success = false
      this.selectedData = data
      this.viewRatingFormDekstop = true
    },
    handleClose () {
      this.showNotif = false
      this.success = true
    },
    handleSuccess () {
      this.viewRatingFormDekstop = false
      this.viewRatingForm = false
      this.showNotif = true
      this.message = {
        title: 'Thank You',
        message: 'Your review is matter for us! it will helpful for other customer too',
        action: '',
        btnText: 'OK',
      }
    }
  },
  mounted () {
    this.setMetaInfo(null, 'Reviews')
    this.$store.commit('setIsHome', false)
    this.$store.commit('setIsLoginPage', false)
    this.$store.commit('setIsMemberPage', true)
    this.$store.commit('setActivePage', 'Reviews')
  }
}
</script>

<style scoped>
.text {
  color: #000000;
  margin-bottom: 5px;
  font-size: 0.9rem !important;
  font-style: normal !important;
}
</style>