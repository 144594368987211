<template>
  <v-card class="pt-2 pb-1 pl-4 pr-4" :elevation="isMobile ? '1' : '0'">
    <v-layout v-if="isMobile" wrap>
      <v-flex xs2>
        <img class="pc-img" :src="data.img_product" :alt="data.product_name">
      </v-flex>
      <v-flex xs6 class="pl-2">
        <p class="pc-title mb-4">{{data.product_name}}</p>
        <p class="pc-sub mb-0">{{data.ukuran}}</p>
      </v-flex>
      <v-flex xs4>
        <p class="pc-sub mb-0" align="right">{{ formatDate(data.order_date)}}</p>
      </v-flex>
      <v-flex xs12 class="mt-2 mb-2">
        <v-btn color="#000" width="100%" height="30" @click="$emit('rating', data)">
          <span class="white--text"> Review</span>
        </v-btn>
      </v-flex>
    </v-layout>

    <v-layout v-else wrap style="position:relative">
      <v-flex xs12 class="mb-4">
        <div class="pc-title-wrapper">
          <span class="pc-title">Order : {{ data.invoice_number}}</span>
          <div class="dot"></div>
        </div>
      </v-flex>
      <v-flex xs1>
        <img class="pc-img" :src="data.img_product" :alt="data.product_name">
      </v-flex>
      <v-flex xs11>
        <v-layout wrap>
          <v-flex xs6 class="pl-2">
            <p class="pc-title mb-4">{{data.product_name}}</p>
            <p class="pc-sub mb-0">{{data.ukuran}}</p>
          </v-flex>
          <v-flex xs6>
            <p class="pc-sub mb-0" align="right">{{ formatDate(data.order_date)}}</p>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-btn class="pc-btn" color="#000" width="15%" height="34px" @click="$emit('ratingDekstop', data)">
        <span class="white--text"> Review</span>
      </v-btn>
    </v-layout>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ProductCard',
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
     ...mapState([
      'isMobile',
      'token'
    ])
  }
}
</script>

<style scoped>
.pc-img {
  width: 100%;
}
.pc-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.pc-sub {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #898989;
}
.pc-btn {
  position: absolute;
  bottom: 5%;
  right: 0;
}
.pc-title-wrapper {
  width: 222px;
  position: relative;
}
.dot {
  width: 5px;
  height: 5px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  right: 0;
}
</style>