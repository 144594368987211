<template>
  <div class="r-image">
    <div class="rating-image-wrapper">
      <img v-for="(item, i) in images" :key="i" :src="item" alt="rating-image" class="rating-image" @click="openSlider()">
    </div>

     <v-dialog v-model="sliderDialog"
      :fullscreen="true"
      transition="dialog-bottom-transition"
      scrollable
      :persistent="false"
    >
      <PreviewImage v-if="sliderDialog" :images="images" @close="sliderDialog = false" />
    </v-dialog>
  </div>
</template>

<script>
import PreviewImage from '@/components/Rating/PreviewImage'

export default {
  name: 'RatingImage',
  components: { PreviewImage },
  props: {
    images: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      sliderDialog: false
    }
  },
  methods: {
    openSlider () {
      this.sliderDialog = true
    }
  }
}
</script>

<style scoped>
.r-image {
  cursor: pointer;
}
.rating-image {
  width: 75px;
  height: 75px;
  object-fit: contain;
  object-position: center;
  border-radius: 10px;
  margin: 0 3px;
}
.rating-image :first-child {
  margin-left: 0 !important;
}
.rating-image :last-child {
  margin-right: 0 !important;
}
</style>