<template>
  <div class="r-waiting mb-16">
    <div v-for="(item,i) in data" :key="i" class="mt-2">
      <ReviewedProductCard :data="item" v-ripple />
      <v-divider v-if="!isMobile" class="mt-1 mb-1"></v-divider>
    </div>
    <div class="mt-4">
      <RatingPagination v-if="total && total > 1" :length="total" @toPage="page = $event" />
    </div>
  </div>
</template>

<script>
import axios from '@/axios'
import { mapState } from 'vuex'
import ReviewedProductCard from '@/components/MyReview/ReviewedProductCard'
import RatingPagination from '@/components/Rating/RatingPagination'

export default {
  name: 'Reviewed',
  components: { ReviewedProductCard, RatingPagination },
  computed: {
    ...mapState([
      'isMobile',
      'token'
    ])
  },
  watch: {
    page () {
      this.getData()
    }
  },
  data () {
    return {
      page: 1,
      data: [],
      total: 0
    }
  },
  methods: {
     getData () {
      this.$store.commit('setIsLoading', true)
      
      axios.defaults.headers = {
        id_brand: process.env.VUE_APP_BRAND,
        token: this.token,
        current_page: this.page,
        status: 'PUBLISHED',
        max_record: 10
      }

      axios.get('/api/v1/pages/rating')
        .then(response => {
          if (response.data.status) {
            this.data = response.data.data.data_rating
            this.total = response.data.data.total_page
          } else {
            this.data = []
          }
        })
        .catch(error => {
          this.errorHandling(error)
        })
        .finally(() => {
          this.$store.commit('setIsLoading', false)
        })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>