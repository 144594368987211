<template>
  <v-sheet
    elevation="0"
    class="py-1"
  >
    <v-chip
      v-for="(item, i) in templates"
      :key="i"
      class="my-1 mx-2"
    >
      <span @click="choose(item)">
        {{ item }}
      </span>
    </v-chip>

  </v-sheet>
</template>

<script>
export default {
  name: 'TemplateText',
  data () {
    return {
      templates: ['Good Quality!', 'Nice Move!', 'So Cheap', 'Never doubt of this product', 'Good Service']
    }
  },
  methods: {
    choose (item) {
      this.$emit('add', item)
    }
  }
}
</script>