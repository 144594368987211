<template>
  <v-card class="pt-2 pb-1 pl-4 pr-4" :elevation="isMobile ? '1' : '0'">
    <v-layout wrap v-if="isMobile">
      <v-flex xs8 class="pl-2">
        <p class="pc-title mb-1" @click="toProduct(data)">{{data.product_name}}</p>
        <p class="pc-sub mb-1">{{data.ukuran}}</p>
        
        <img v-for="n in length" :key="n" src="@/assets/star.svg" alt="star" width="14">
        <p class="pc-sub2 mt-2 mb-2">{{data.review}}</p>
      </v-flex>
      <v-flex xs4>
        <p class="pc-sub mb-0" align="right">{{ formatDate(data.order_date)}}</p>
      </v-flex>
      <v-flex xs12>
        <RatingImage :images="data.url_image" />
      </v-flex>
    </v-layout>

    <v-layout wrap v-else>
      <v-flex xs1 @click="toProduct(data)">
        <img class="pc-img" :src="data.img_product" :alt="data.product_name">
      </v-flex>
      <v-flex xs7 class="pl-2">
        <p class="pc-title mb-2" @click="toProduct(data)">{{data.product_name}}</p>
        <p class="pc-sub mb-2">{{data.ukuran}}</p>
        <img v-for="n in length" :key="n" src="@/assets/star.svg" alt="star" width="14">
      </v-flex>
      <v-flex xs4>
        <p class="pc-sub mb-0" align="right">{{ formatDate(data.order_date)}}</p>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import RatingImage from '@/components/Rating/RatingImage'

export default {
  name: 'ReveiwedProductCard',
  components: { RatingImage },
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
     ...mapState([
      'isMobile',
      'token'
    ]),
    length () {
      return Number(this.data.rating)
    }
  }
}
</script>

<style scoped>
.pc-img {
  width: 100%;
  cursor: pointer;
}
.pc-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  cursor: pointer;
}
.pc-sub {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #898989;
}
.pc-sub2 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}
</style>