<template>
  <div class="r-waiting mb-16">
    <div v-for="(item,i) in data" :key="i" class="mt-2">
      <ProductCard :data="item" @rating="$emit('rating', $event)" @ratingDekstop="$emit('ratingDekstop', $event)"/>
      <v-divider v-if="!isMobile" class="mt-1 mb-1"></v-divider>
    </div>
    <div class="mt-4">
      <RatingPagination v-if="total && total > 1" :length="total" @toPage="page = $event" />
    </div>
  </div>
</template>

<script>
import axios from '@/axios'
import { mapState } from 'vuex'
import ProductCard from '@/components/MyReview/ProductCard'
import RatingPagination from '@/components/Rating/RatingPagination'

export default {
  name: 'Waiting',
  components: { ProductCard, RatingPagination },
  props: {
    success: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState([
      'isMobile',
      'token'
    ])
  },
  watch: {
    page () {
      this.getData()
    },
    success () {
      this.getData()
      this.getNotif()
    }
  },
  data () {
    return {
      page: 1,
      data: [],
      total: 0,
      error: ''
    }
  },
  methods: {
    getData () {
      this.$store.commit('setIsLoading', true)
      
      axios.defaults.headers = {
        id_brand: process.env.VUE_APP_BRAND,
        token: this.token,
        current_page: this.page,
        status: 'OPEN',
        max_record: 10
      }

      axios.get('/api/v1/pages/rating')
        .then(response => {
          if (response.data.status) {
            this.data = response.data.data.data_rating
            this.total = response.data.data.total_page
          } else {
            this.data = []
          }
        })
        .catch(error => {
          this.errorHandling(error)
        })
        .finally(() => {
          this.$store.commit('setIsLoading', false)
        })
    },
    getNotif () {
      axios.defaults.headers = {
        id_brand: process.env.VUE_APP_BRAND,
        token: this.token,
      }

      axios.get('/api/v1/pages/notification')
        .then(response => {
          if (response.data.status) {
            this.$store.commit('setNotifNumber', response.data.data.total)
            this.$store.commit('setReviewNumber', response.data.data.total_rating)
          }
        })
        .catch(error => {
          this.error = error
        })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>