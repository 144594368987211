<template>
  <div class="fs-slider">
    <v-btn class="close-button" icon large @click="$emit('close')">
      <v-icon large> mdi-close </v-icon>
    </v-btn>
    <slider ref="slider" :options="options">
      <slideritem v-for="(item,index) in images" :key="index">
        <div>
          <img v-bind:src="item" :width="isMobile ? '100%' : 'auto'" :height="isMobile ? 'auto' : '100%'" :alt="item.img1">
        </div>
      </slideritem>
      <div slot="loading">loading...</div>
    </slider>

    <v-btn
      class="mx-2 nav-left"
      color="#000"
      fab
      small
      @click="previous()"
    >
      <v-icon large color="white">
        mdi-chevron-left
      </v-icon>
    </v-btn>

      <v-btn
      class="mx-2 nav-right"
      color="#000"
      fab
      small
      @click="next()"
    >
      <v-icon large color="white">
        mdi-chevron-right
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { slider, slideritem } from 'vue-concise-slider'

  export default {
    name: 'PreviewImage',
    components: {
      slideritem,
      slider
    },
    props: {
      images: {
        type: Array,
        default: () => {
          return []
        }
      },
      options: {
        type: Object,
        default: () => {
          return {
            currentPage: 0,
            direction: 'horizontal',
            pagination: false,
            loop:true
          }
        }
      }
    },
    computed: {
      ...mapState([
        'isMobile'
      ])
    },
    data () {
      return {
        index: 0
      }
    },
    methods: {
      next () {
        if (this.index === this.images.length - 1 ) {
          this.index = 0
        } 
        else {
          this.index += 1
        }
        this.$refs.slider.$emit('slideTo', this.index)
      },
      previous () {
        if (this.index === 0 ) {
          this.index = this.images.length - 1
        } 
        else {
          this.index -= 1
        }
        this.$refs.slider.$emit('slideTo', this.index)
      }
    }
  }
</script>

<style scoped>
.fs-slider {
  background-color:#fff;
  /* opacity: 0.9; */
}
.close-button {
  position: absolute;
  top: 2%;
  right: 2%;
  z-index: 999;
}
.nav-left {
  position: absolute;
  top: 50%;
  left: 2%;
  transform: translateY(-50%);
  z-index: 99;
  opacity: 0.5;
}
.nav-right {
  position: absolute;
  top: 50%;
  right: 2%;
  transform: translateY(-50%);
  z-index: 99;
  opacity: 0.5;
}
</style>